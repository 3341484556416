const darkGrey = "#3e5161"

export default {
  colors: {
    text: "black",
    headerBackground: "black",
    headerText: "white",
    paperBackgroundColor: "white",
    paperHoverBackgroundColor: "white",
    paperHeadingColor: "black",
    paperHoverHeadingColor: darkGrey,
    paperBorderColor: "transparent",
    paperDescriptionColor: darkGrey,
    collectionBackgroundColor: "white",
    collectionHeading: "black",
    collectionDescription: darkGrey,
    breadcrumbLinkTextColor: "black",
    breadcrumbHoverLinkTextColor: "black",
    breadcrumbTextColor: darkGrey,
    articleTextColor: darkGrey,
    articleDescriptionColor: darkGrey,
    background: "white",
    primary: "black",
    secondary: "#BB263B",
    muted: darkGrey,
    searchTextColor: "rgba(255,255,255,0.7)",
    searchTextFocusColor: darkGrey,
    searchTextPlaceholderColor: "white",
    searchTextFocusPlaceholderColor: "black",
    comboboxColor: "black",
    iconColor: "black",
    logoColor: "white",
    footerTextColor: "black",
    footerTextHoverColor: "black",
  },
  radii: [0, 2, 4, 8],
  fonts: {
    body: "Arial, sans-serif",
    heading: "Arial, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 600,
  },
  lineHeights: {
    small: 1.3,
    body: 1.4,
    heading: 1.2,
  },
  fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  /* Styles for MDX */
  styles: {
    // the keys used here reference elements in MDX
    h1: {
      // the style object for each element
      // can reference other values in the theme
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      marginTop: 20,
      marginBottom: 10,
    },
    a: { color: "black" },
    blockquote: {
      marginLeft: "1.75rem",
    },
    ul: {
      marginBottom: 10,
    },
    // more styles can be added as needed
  },
}
